<template>
    <div class="cardDayReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==0}" @click="nowadays()">
                    <div class="title">
                        <span>今天 <br/>({{new Date().Format("yyyy-MM-dd")}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==1}" @click="queryType=1">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==0'
                    > </el-date-picker>
                </div>
                <div class="bnt-search" @click="GetMemberConsumeInfo()" >查询</div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="print()"><i class="iconfont icon-dayin"></i>打印报表</div>
            </div>
        </div>
        <div class="content-flex">
            <div class="printExample" >
                <div class="scroll-box" v-scroll-anime="{up:'page-up',down:'page-down'}" v-mouse-scroll>
                    <div v-html="Print_Html"></div>
                </div>
            </div>
            <div class="page-bnt clearfix">
                <div class="bnt page-up" ref="page-up"><i class="iconfont icon-fangxiang-up"></i></div>
                <div class="bnt page-down" ref="page-down"><i class="iconfont icon-fangxiang-down"></i></div>
            </div>
        </div>
     </div>
</template>

<script>

import { printTrJson,printJson,printView } from '../../../../../common'
/**会员交易汇总表 */
export default {
    name:'cardDayReport',
    data(){
        return {
            userInfo:{},
            istag: true,
            /**汇总数据 */
            summaryData:null,
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            queryType:0, //0当天数据,1按日期范围
        }
    },
    computed:{
        /** 打印数据展示 html **/
        Print_Html(){
            return printView(this.PrintBodies);
        },
        /** 打印数据 json **/
        PrintBodies(){
            let printLen=40;
            let defaultJson={
                DataItem_PrintLen:printLen
            }
            let printData=printJson("【会员交易汇总表】",{DataItem_FontSize:11,DataItem_FontAlign:0,DataItem_PrintLen:printLen});
            printData=printData.concat(printJson(""));
            let Rpt_Date=new Date(this.userInfo?.Rpt_Date).Format("yyyy/MM/dd")
            if(this.queryType){
                Rpt_Date=new Date(this.businessHours[0]).Format("yyyy/MM/dd")+"-"+new Date(this.businessHours[1]).Format("yyyy/MM/dd");
            }
           
            printData=printData.concat(printJson("交易门店："+this.userInfo?.Department_Name,defaultJson));
            printData=printData.concat(printJson("报表日期："+Rpt_Date,defaultJson));
            printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));

            printData=printData.concat(printJson("【新增会员】",defaultJson));
            printData=printData.concat(printJson("新增会员卡数:"+(this.summaryData?.MemberNewGen||0),defaultJson));
            if(this.summaryData?.MemberRechargeTimes>0){
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                printData=printData.concat(printJson("【充值汇总】",defaultJson));
                printData=printData.concat(printJson("充值笔数:"+this.summaryData.MemberRechargeTimes+"笔",defaultJson));
                printData=printData.concat(printJson("充值实收储值:"+this.summaryData.MemberRechargeFactMoney.toFixed(2)+"元",defaultJson));
                if(this.summaryData.MemberRechargePayments?.length>0){
                    this.summaryData.MemberRechargePayments.forEach(item=>{
                        let json=[{
                            "DataItem_Key": "PaymentCount",
                            "DataItem_Name": "∟"+item.PaymentName+"："+(item.PaymentMoney.toFixed(2))+"元",
                            "DataItem_FontSize": 10,
                            DataItem_PrintLen:printLen,
                            DataItem_FontAlign:2
                        }]
                        printData=printData.concat(printTrJson(json,{PaymentCount:item.PaymentCount+"笔"},0));
                    })
                    
                }
                printData=printData.concat(printJson("充值赠送金额："+this.summaryData?.MemberRechargeGiftMoney+"元",defaultJson));
            }

            if(this.summaryData?.MemberConsumeTimes>0){
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                printData=printData.concat(printJson("【消费汇总】",defaultJson));
                printData=printData.concat(printJson("消费笔数:"+this.summaryData.MemberConsumeTimes+"笔",defaultJson));
                printData=printData.concat(printJson("会员消费总额:"+this.summaryData.MemberConsumeMoney.toFixed(2)+"元",defaultJson));
                printData=printData.concat(printJson("∟消费实收储值："+(this.summaryData.MemberConsumeFactMoney.toFixed(2))+"元",defaultJson));
                printData=printData.concat(printJson("∟消费赠送储值："+(this.summaryData.MemberConsumeGiftMoney.toFixed(2))+"元",defaultJson));
                printData=printData.concat(printJson("会员消费返积分数:"+this.summaryData.MemberConsumeReceivePoints,defaultJson));
            }
            
            if(this.summaryData?.MemberRefundCardTimes>0){
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                printData=printData.concat(printJson("【退卡汇总】",defaultJson));
                printData=printData.concat(printJson("退卡张数:"+this.summaryData.MemberRefundCardTimes+"张",defaultJson));
                printData=printData.concat(printJson("退款金额:"+this.summaryData.MemberRefundCardMoney.toFixed(2)+"元",defaultJson));
                
                if(this.summaryData.MemberRefundPayments?.length>0){
                    this.summaryData.MemberRefundPayments.forEach(item=>{
                        let json=[{
                            "DataItem_Key": "PaymentCount",
                            "DataItem_Name": "∟"+item.PaymentName+"："+(item.PaymentMoney.toFixed(2))+"元",
                            "DataItem_FontSize": 10,
                            DataItem_PrintLen:printLen,
                            DataItem_FontAlign:2
                        }]
                        printData=printData.concat(printTrJson(json,{PaymentCount:item.PaymentCount+"笔"},0));
                    })
                    
                }
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                let totalMoney=this.summaryData.MemberRechargeFactMoney-this.summaryData.MemberRefundCardMoney;
                printData=printData.concat(printJson("（充值实收储值-退款金额）小计："+totalMoney.toFixed(2)+"元",defaultJson));
            }

            if(this.summaryData?.MemberGetCardCostTimes>0){
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                printData=printData.concat(printJson("【收工本费】",defaultJson));
                printData=printData.concat(printJson("收笔数:"+this.summaryData.MemberGetCardCostTimes+"笔",defaultJson));
                printData=printData.concat(printJson("收工本费:"+this.summaryData.MemberGetCardCostMoney.toFixed(2)+"元",defaultJson));
                if(this.summaryData.MemberGetCardCostPayments?.length>0){
                    this.summaryData.MemberGetCardCostPayments.forEach(item=>{
                        let json=[{
                            "DataItem_Key": "PaymentCount",
                            "DataItem_Name": "∟"+item.PaymentName+"："+(item.PaymentMoney.toFixed(2))+"元",
                            "DataItem_FontSize": 10,
                            DataItem_PrintLen:printLen,
                            DataItem_FontAlign:2
                        }]
                        printData=printData.concat(printTrJson(json,{PaymentCount:item.PaymentCount+"笔"},0));
                    })
                    
                }
            }

            if(this.summaryData?.MemberRefundCardCostTimes>0){
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                printData=printData.concat(printJson("【退工本费】",defaultJson));
                printData=printData.concat(printJson("退笔数:"+this.summaryData.MemberRefundCardCostTimes+"笔",defaultJson));
                printData=printData.concat(printJson("退工本费:"+this.summaryData.MemberRefundCardCostMoney.toFixed(2)+"元",defaultJson));
                
                if(this.summaryData.MemberRefundCardCostPayments?.length>0){
                    this.summaryData.MemberRefundCardCostPayments.forEach(item=>{
                        let json=[{
                            "DataItem_Key": "PaymentCount",
                            "DataItem_Name": "∟"+item.PaymentName+"："+(item.PaymentMoney.toFixed(2))+"元",
                            "DataItem_FontSize": 10,
                            DataItem_PrintLen:printLen,
                            DataItem_FontAlign:2
                        }]
                        printData=printData.concat(printTrJson(json,{PaymentCount:item.PaymentCount+"笔"},0));
                    })
                }
                printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
                let totalMoney=this.summaryData.MemberGetCardCostMoney-this.summaryData.MemberRefundCardCostMoney;
                printData=printData.concat(printJson("（收工本费-退工本费）小计："+totalMoney.toFixed(2)+"元",defaultJson));
            }
            

            

            printData.push(printJson('',{baseline:'-',DataItem_PrintLen:printLen}));
            printData=printData.concat(printJson("",defaultJson));
            printData=printData.concat(printJson("打印时间："+new Date().Format("yyyy-MM-dd hh:mm:ss"),{DataItem_FontSize:11,DataItem_PrintLen:printLen}));
            printData=printData.concat(printJson("打印人："+this.userInfo.Login_Name,{DataItem_FontSize:11,DataItem_PrintLen:printLen}));

            let text=[];
            printData.forEach((item,index)=>{
                item.Print_Line= index+1;
                text.push(item.Print_Text)
            })
            console.log(printData.map(it=>it.Print_Text).join('\r\n'))
            return printData;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.$nextTick(()=> {
            this.queryType=0
        })
    },
    methods:{
        nowadays(){
            this.queryType=0
            this.businessHours=[new Date(),new Date()]
            this.GetMemberConsumeInfo()
        },
        change(){
            if(this.queryType==0){
                this.queryType=1
            }
        },
        /**获取会员消费明细 */
        GetMemberConsumeInfo(){
            let userInfo=this.$auth.getUserInfo();
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:userInfo?.User_ID,
                Rpt_Date:new Date().Format("yyyy-MM-dd"), //营业日期
                Range_BeginDate: new Date(time[0]).Format("yyyy-MM-dd"),//范围开始日期
                Range_EndDate:new Date(time[1]).Format("yyyy-MM-dd"),//范围结束日期
                Query_Type:this.queryType, //0当天数据,1按日期范围
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:',param)
            this.$httpAES.post("Bestech.CloudPos.GetMemberBusinessSummaryData",param).then((d)=>{
                loading.close();
                console.log('返回内容:',d)
                if(d.ResponseHeader.ResultCode==0){
                    this.summaryData = d.ResponseBody;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**打印 */
        print(){
            if(this.summaryData?.Printer_Name && this.summaryData?.Printer_Type>=0){
                let printJson=[ {
                    PrintBodies:this.PrintBodies,
                    "Print_Num":1,
                    "Print_Serial": "",
                    "Print_TemplateID": "",
                    "Printer_Name": this.summaryData.Printer_Name,
                    "Printer_Type": this.summaryData.Printer_Type,
                }];
                this.$webBrowser.posPrint(printJson);
                this.$message.success("已发送打印指令");
            }else{
                this.$message.success("未获取到打印设备信息");
            }
        },
    },
}
</script>

<style lang="scss">
@import './cardDayReport.scss'
</style>